<template>
  <div class="SettingsHeader m-b-l has-background-grey-lighter">
    <div class="columns is-marginless">
      <div class="column is-2 is-flex is-aligned-center">
        <round-avatar
          :src="imgSrc"
          size="x-large"
        />
      </div>
      <div class="column is-10">
        <div class="Profile__header__details">
          <h3 class="title is-3">
            <slot name="name">
              {{ name }}
            </slot>
          </h3>
          <div class="Profile__header-meta is-size-5 has-text-grey">
            <slot name="meta">
              {{ meta }}
            </slot>
          </div>
          <div v-if="countryData" class="is-flex m-t-m">
            <country-flag :country="countryData.iso_alpha2" class="nationality" />
            <span class="is-uppercase is-size-7 has-text-weight-semibold has-text-grey">{{ countryData.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    meta: {
      type: String,
      default: ''
    },
    country: {
      type: [Object, Array],
      default: null
    }
  },
  computed: {
    countryData () {
      return Array.isArray(this.country)
        ? this.country.length ? this.country[0] : null
        : this.country
    }
  }
}
</script>
